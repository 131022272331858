<template>
    <div class="giga-fields">
        <div class="giga-input_field half-field">
            <label class="input-field_label">SIRET</label>
            <div class="input-field_area">
                <input type="text" v-model="$v.agenceForm.siret.$model" :class="{ 'field-hasError': $v.agenceForm.siret.$invalid && $v.agenceForm.siret.$anyDirty}" @keyup="emitSiret">
            </div>
              <small class="form-text text-danger" v-if="$v.agenceForm.siret.$invalid && $v.agenceForm.siret.$anyDirty">
                {{$t('siret_invalid')}}
              </small>
              <small class="form-text text-danger" v-if="!$v.agenceForm.siret.$invalid && $v.agenceForm.siret.$anyDirty && agencyNotFound">
                {{$t('agency_not_found')}}
              </small>
        </div>
        <div class="giga-input_field half-field">
            <label class="input-field_label">{{ $t("company_name") }}</label>
            <div class="input-field_area">
                <input type="text" v-model="$v.agenceForm.raisonSocial.$model">
            </div>
            <div v-if="$v.agenceForm.raisonSocial.$anyDirty && $v.agenceForm.raisonSocial.$invalid">
              <small class="form-text text-danger" v-if="!$v.agenceForm.raisonSocial.required">
                {{$t('required_field')}}
              </small>
            </div>

        </div>
        <div class="giga-input_field full-field">
            <label class="input-field_label">{{ $t('address') }}</label>
            <div class="input-field_area">
                <input type="text" v-model="$v.agenceForm.address.$model">
            </div>
            <div v-if="$v.agenceForm.address.$anyDirty && $v.agenceForm.address.$invalid">
              <small class="form-text text-danger" v-if="!$v.agenceForm.address.required">
                {{$t('required_field')}}
              </small>
            </div>

        </div>
    </div>
</template>

<script>

export default {
  name: "AgenceInfos",
  props: ['agenceForm', '$v', 'agencyNotFound'],
  computed:{

  },
  methods:{
    emitSiret() {
      if (this.$v.agenceForm.siret.$invalid) {
        return;
      }
      this.$emit('onSiretChange');
    }
  }
}

</script>
